<template>
  <SvgIcon
    v-if="level !== 'not_clean'"
    :icon="selectedIcon"
    :class="['cleanscents', `cleanscents-icon-${icon}`]"
    alt="CandleScience CleanScents"
  />
</template>

<script lang="ts" setup>
  const props = defineProps<{
    icon: "square" | "text"
    level: CsFragrance["cleanScentLevel"]
  }>()

  const selectedIcon = computed(() => {
    switch (props.icon) {
      case "square":
        switch (props.level) {
          case "clean_scent_plus":
            return "cleanscents-plus"
          default:
            return "cleanscents"
        }
      default:
        switch (props.level) {
          case "clean_scent_plus":
            return "cleanscents-plus-logotype"
          default:
            return "cleanscents-logotype"
        }
    }
  })
</script>

<style lang="scss" scoped>
  .cleanscents {
    display: block;
  }
  .cleanscents-icon-square {
    height: 28px;
    width: 28px;
  }
  .cleanscents-icon-text {
    height: 44px;
    width: 198px;
  }
</style>
